<template>
	<div style="background: #fff;padding: 15px;" class="widthDrawal-box">
		<buttonPermissions :datas="'clueSourceEdit'" >
			<el-button type="primary" @click="handleEditSource(false)">添加来源</el-button>
		</buttonPermissions>

		<el-table :data="list" v-loading="loading" class="margin-top-20">
			<el-table-column prop="FromName" label="线索来源"></el-table-column>
			<el-table-column prop="AddTime" label="添加时间"></el-table-column>
			<el-table-column prop="" label="操作" width="200px">
				<template slot-scope="scope">
					<div class="flex flex-align-center flex-wrap gap-10" v-if="!scope.row.IsSystemPreset">
						<div>
							<buttonPermissions :datas="'clueSourceEdit'" >
								<el-button type="text" @click="handleEditSource(scope.row)">编辑</el-button>
							</buttonPermissions>
						</div>
						<div>
							<buttonPermissions :datas="'clueSourceDelete'" >
								<el-button type="text" style="color:#F56C6C" @click="handleDelete(scope.row)">删除</el-button>
							</buttonPermissions>
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="text-align:right" class="margin-top-10" v-if="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
		</el-pagination>
		
		
		<!-- 添加编辑线索来源 -->
		<el-dialog :title="sourceDialogTitle" :visible.sync="sourceVisible" width="450px">
			<el-form :model="sourceForm" :rules="sourceRules" label-width='88px' ref="sourceForm">
				<el-form-item label="来源名称:" prop="FromName" style='margin-bottom:10px;'>
					<el-input placeholder="来源名称" v-model="sourceForm.FromName" style="width: 311px;" maxlength="50"></el-input>
				</el-form-item>
			</el-form>
			<div style="padding-top:20px;text-align: right;">
				<el-button @click="sourceVisible=false">关闭</el-button>
				<el-button type="primary" :loading="saveLoading" @click="handleSave">确定</el-button>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	
	import {
		cRMClueFromList,
		cRMClueFromSave,
		cRMClueFromDelete
	} from '@/api/cluesManage';
	
	import buttonPermissions from '@/components/buttonPermissions';
	
	export default {
		components: {buttonPermissions},
		data() {
			return {
				list: [],
				total: 0,
				pageIndex: 1,
				pageSize: 20,
				loading: false,
				
				sourceVisible: false,
				sourceDialogTitle: '',
				saveLoading: false,
				sourceForm: {
					FromName: '',
					Id: '',
				},
				sourceRules: {
					FromName: {
						required: true,
						message: '请输入来源名称',
						trigger: 'blur'
					}
				}
			}
		},
		methods: {
			async getList(){
				this.loading = true;
				
				try{
					let params = {
						Skip: (this.pageIndex-1) * this.pageSize,
						Take: this.pageSize
					};
					const res = await cRMClueFromList(params);
					this.list = res.Result.Results;
					this.total = res.Result.Total;
					this.loading = false;
				}catch(e){
					this.loading = false;
				}
			},
			
			/**
			 * 分页
			 */
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.getList();
			},
			
			/**
			 *  删除线索来源
			 */
			handleDelete(e){
				this.$confirm('删除来源后，对应线索的来源将会清空。该操作不可恢复，是否确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.sureDelete(e.Id);
				})
			},
			async sureDelete(Id){
				try{
					const res = await cRMClueFromDelete({ Id });
					this.$message.success('操作成功');
					this.getList();
				}catch(e){
				}
			},
			
			/**
			 *  添加编辑线索来源
			 */
			handleEditSource(e){
				if (e){
					this.sourceForm.FromName = e.FromName;
					this.sourceForm.Id = e.Id;
					this.sourceDialogTitle = '编辑线索来源';
				}else{
					this.sourceForm.FromName = '';
					this.sourceForm.Id = '';
					this.sourceDialogTitle = '添加线索来源';
				}
				
				this.sourceVisible = true;
			},
			handleSave(){
				this.$refs['sourceForm'].validate((valid) => {
					if (valid) {
						this.sureSaveData();
					}
				});
			},
			async sureSaveData(){
				this.saveLoading = true;
				try{
					const res = await cRMClueFromSave(this.sourceForm);
					this.sourceVisible = false;
					this.pageIndex = 1;
					this.$message.success('操作成功');
					this.getList();
				}catch(e){
					
				}
				this.saveLoading = false;
			}
		},
		created() {
			this.getList();
		}
	}
</script>

<style lang="less" scoped>

</style>